import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { Fade } from 'react-bootstrap';

const InputError = ({ error, message }) => (
  <Fade in={error}>
    <FormHelperText error>{message}</FormHelperText>
  </Fade>
);

export default InputError;
